import { ThemeProvider } from '@mui/material';
import Header from '../../components/Header';
import Layout from '../../components/Layout';
import { BrowserRouter as Router } from 'react-router-dom';
import RouterSwitch from './RouterSwitch';
import theme from '../../utils';

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Layout>
          <Header />
          <RouterSwitch />
        </Layout>
      </ThemeProvider>
    </Router>
  );
}

export default App;
