import { Box, IconButton } from '@mui/material';
import {
  FaGithub,
  FaStackOverflow,
  FaLinkedin,
  FaMedium,
  FaDev,
} from 'react-icons/fa';

const socialUrls = [
  {
    url: 'https://github.com/chilupa',
    source: 'github',
    component: <FaGithub />,
  },
  {
    url: 'https://stackoverflow.com/story/pavanchilukuri',
    source: 'stackoverflow',
    component: <FaStackOverflow />,
  },
  {
    url: 'https://dev.to/chilupa',
    source: 'dev',
    component: <FaDev />,
  },
  {
    url: 'https://medium.com/@chilupa',
    source: 'medium',
    component: <FaMedium />,
  },
  {
    url: 'https://www.linkedin.com/in/pavanchilukuri/',
    source: 'linkedin',
    component: <FaLinkedin />,
  },
];

const handleOpenBrowser = (url) => window.open(url, '_blank');

const SocialLinks = () => (
  <Box textAlign="center" pt={1}>
    {socialUrls.map((props, index) => (
      <IconButton
        key={index}
        color="success"
        onClick={() => handleOpenBrowser(props.url)}
        size="large"
      >
        {props.component}
      </IconButton>
    ))}
  </Box>
);

export default SocialLinks;
