import { Box, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const PageLinks = () => {
  const links = [
    {
      to: '/',
      name: 'About',
    },
    {
      to: '/projects',
      name: 'Projects',
    },
    {
      to: '/contact',
      name: 'Contact',
    },
  ];
  const Route = ({ children }) => (
    <Typography color="primary" component="span" variant="body1">
      {children}
    </Typography>
  );
  return (
    <Box textAlign="center" p={1}>
      {links.map(({ to, name }, index) => (
        <Box key={index} component="span" pl={2} pr={2}>
          <Link component={RouterLink} to={to} underline="hover">
            <Route>{name}</Route>
          </Link>
        </Box>
      ))}
    </Box>
  );
};

export default PageLinks;
