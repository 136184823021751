import React from 'react';
import { Typography } from '@mui/material';

const Title = () => (
  <Typography variant="h3" component="h1" align="center" color="primary">
    Pavan Chilukuri
  </Typography>
);

export default Title;
