import { Box, Divider } from '@mui/material';
import PageLinks from './PageLinks';
import SocialLinks from './SocialLinks';
import Title from './Title';

const Header = () => {
  return (
    <Box>
      <Title />
      <SocialLinks />
      <PageLinks />
      <Box p={2}>
        <Divider />
      </Box>
    </Box>
  );
};

export default Header;
